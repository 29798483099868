import React from 'react'
import PropTypes from 'prop-types'
import slugify from 'slugify'

export default function Auction({ pageContext: { auction } }) {
  return (
    <div>
      <div className="bg-white relative py-100">
        <div className="container">
          <div className="flex flex-wrap -mx-8">
            {auction.Lots.map((lot) => (
              <div
                className="w-full md:w-1/2 lg:w-1/3 px-8 mb-20 md:mb-40"
                key={lot.ID}
              >
                <img
                  alt="Lot"
                  src={lot.Thumbnail.replace(
                    `_enhanced_thumb`,
                    `_web_medium`
                  ).replace(`_thumb`, `_web_medium`)}
                />
                <h3
                  className="mt-20 text-17 lg:text-19 leading-snug font-semibold"
                  dangerouslySetInnerHTML={{ __html: lot.FullAddress }}
                />
                <p className="text-18 mt-10">ID: {lot.ID}</p>
                <p className="text-18 mt-10">Lot Number: {lot.LotNumber}</p>
                <p className="text-18 mt-10">
                  <a
                    href={`/lot/${slugify(lot.FullAddress, {
                      lower: true,
                      strict: true,
                    })}-${lot.ID}/`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    click here
                  </a>
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

Auction.propTypes = {
  pageContext: PropTypes.object.isRequired,
}
